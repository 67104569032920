import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { RESPONSE_DATA } from "redux/constants";
import Bookmark2Icon from "assets/images/bookmark_2.svg";
import WordIcon from "assets/images/word_icon.svg";
import Image from "components/shared/Image";
import { downloadFileFromUrl } from "utils/helpers/helpers";
import { Tooltip } from "components/shared/Tooltip";
import "./Bookmarks.scss";

const {
  GET_ALL_BOOKMARK,
  DELETE_BOOKMARK
} = RESPONSE_DATA;


export const Bookmarks = () => {
  const dispatch = useDispatch();
  const bookmarkedTranscripts = useSelector(responseSelector.getBookmarkedTranscripts);
  const selectedTrancript = useSelector(responseSelector.getSelectedTranscript);
  const openTranscriptInNewTab = (event, transcript) => {
    event.stopPropagation();
    //sendToLocalStorage("eVar2ForDetailedTranscript", "fromKeywordSearch");
    downloadFileFromUrl(`transcript/${transcript.meetingId}`, "_blank");
  };
  useEffect(() => {
    dispatch({ type: GET_ALL_BOOKMARK, payload: {} });
  }, []);
  const unBookmarkFile = (transcript) => {
    dispatch({ type: DELETE_BOOKMARK, payload: { transcript, bookmarkedTranscripts, selectedTrancript } });
  }
  return (
    <div className="bookmarks_container">
      <div className="bookmarks">
        {bookmarkedTranscripts.map((transcript) => {
          return (
            <div className="bookmark" key={transcript.id}>
              <div className="bookmark__heading">
                <div className="wordIconContainer">
                  <Image className="wordIcon" src={WordIcon} alt="WordIcon" />
                </div>
                <div data-tip data-for='TranscriptTitle' className="title" onClick={(e) => openTranscriptInNewTab(e, transcript)} title={""}>
                  {transcript.keyword.length > 90 ? transcript.keyword.substring(0, 90) + "..." : transcript.keyword}
                </div>
                <Tooltip id="TranscriptTitle" delayHide={0}>Click to open in new tab</Tooltip>
              </div>
              <div className="bookmark__icon">
                <Image data-tip data-for='DeleteBookmark' className="icon" src={Bookmark2Icon} alt="logo" onClick={() => unBookmarkFile(transcript)} />
                <Tooltip id="DeleteBookmark" delayHide={0}>Delete Bookmark</Tooltip>
              </div>

            </div>
          );
        })}
      </div>
    </div >
  );
}



